import React from "react";

import Layout from "../../components/organisms/Layout/Layout";
import SEO from "../../components/atoms/SEO/SEO";
import Section from "../../components/organisms/Section/Section";
import PageWidth from "../../components/organisms/PageWidth/PageWidth";

const NewsletterUpdate = () => (
  <Layout>
    <SEO
      title="Newsletter erfolgreich eingerichtet"
      description="Du bekommst ab sofort den Newsletter"
    />
    <Section>
      <PageWidth>
        <h1>
          Das hat geklappt{" "}
          <span role="img" aria-label="Hand zeigt okay">
            👌
          </span>
        </h1>
        <p>Deine Daten für den Newsletter habe ich aktualisiert.</p>
      </PageWidth>
    </Section>
  </Layout>
);

export default NewsletterUpdate;
